import React, { useState } from 'react';
import { notification, Spin } from 'antd';
import FileSaver from 'file-saver';
import { useAnalyticsEventTracker } from '../tracker/Tracker';
import stats from '../../services/stats';

const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
const Download = ({ children, onDownload, fileName, addStats }) => {
  const [downloading, setDownloading] = useState(false);
  const eventTracker = useAnalyticsEventTracker('Download');

  const download = async () => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    notification.open({
      key: 'downloading',
      message: 'Downloading',
      duration: 0,
      icon: <Spin />,
    });
    try {
      const res = await onDownload();
      const blob = new File([res.data], { type: res.headers['content-type'] });
      FileSaver.saveAs(blob, fileName);
      notification.close('downloading');
      if (addStats) {
        addStats({
          typeName: stats.statLabels.resultDownload,
          projectId: +PROJECT_ID,
        });
      }
      eventTracker('ResultDownload', stats.gaLabels.resultDownload);

      notification.success({
        key: 'download_success',
        message: 'Download succeeded',
      });
    } catch (err) {
      notification.close('downloading');
      notification.error({ key: 'download_fail', message: 'Download failed' });
    }
    setDownloading(false);
  };

  return children(download, downloading);
};

export default Download;
