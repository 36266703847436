import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faEnvelope,
  faCopy,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Download from '../../../../components/download/Download';
import {
  fetchResultPdf,
  addStats,
  disableCalculationResult,
} from '../../../../features/calculators';
import { duplicateCalculationAsync } from '../../calculatorsSlice';
import { decrypt } from '../../../../services/crypto';

const SavedCalculationsActions = ({ record }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailClick = () => {
    record.onEmailClick({
      visible: true,
      refId: record.referenceId,
      insuredName: record.insuredName,
      stepId: record.firstStepId || record.lastUpdatedStepId,
      status: record.status,
    });
  };

  const handleDuplicateClick = async () => {
    await dispatch(duplicateCalculationAsync(record.referenceId));
    record.fetchResultsSummary();
  };

  const handleEditCick = () => {
    const path = `/references/${record.referenceId}/steps/${
      record.lastUpdatedStepId || record.firstStepId
    }`;

    const resultPagePath = `/references/${record.referenceId}/steps/results`;

    if (record.status === 2) {
      navigate(resultPagePath);
    } else {
      navigate(path);
    }
  };

  const handleRemoveClick = async () => {
    await disableCalculationResult(record.referenceId);
    record.fetchResultsSummary();
  };

  return (
    <>
      <Download
        onDownload={() =>
          fetchResultPdf({
            refId: record.referenceId,
            insuredName: decrypt(record.insuredName) || record.insuredName,
          })
        }
        fileName={`BICalculatorResults_${record.referenceId}.pdf`}
        addStats={addStats}
      >
        {(download, downloading) => (
          <Button
            title="Download results pdf"
            size="small"
            type="link"
            onClick={download}
            disabled={record.status !== 2 || downloading}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        )}
      </Download>
      <Button
        title="Send email"
        type="link"
        size="small"
        onClick={handleEmailClick}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </Button>
      <Popconfirm
        title="Duplicate calculation"
        description="duplicate this calculation?"
        onConfirm={handleDuplicateClick}
        okText="Yes"
        cancelText="No"
      >
        <Button title="Duplicate calculation" type="link" size="small">
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      </Popconfirm>
      <Popconfirm
        title="Remove calculation"
        description="Remove this calculation?"
        onConfirm={handleRemoveClick}
        okText="Yes"
        cancelText="No"
      >
        <Button title="Remove calculation" type="link" size="small">
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Popconfirm>
      <Button type="primary" size="small" onClick={handleEditCick}>
        Edit
      </Button>
    </>
  );
};

export default SavedCalculationsActions;
