import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import NavigationMenu from '../navigation-menu/NavigationMenu';
import useWindowResize from '../../../hooks/useWindowResize';
import theme from '../../../../services/theme';
import './header.less';

export default () => {
  const { width } = useWindowResize();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <header className="site-header">
      <div className="site-header-container">
        <div className="site-header-logo">
          {theme.logo.linkType === 'local' ? (
            <Link to={theme.logo.url}>
              <img src={theme.logo.image} alt="logo" />
            </Link>
          ) : (
            <a href={theme.logo.url} target="_blank" rel="noopener noreferrer">
              <img src={theme.logo.image} alt="logo" />
            </a>
          )}
        </div>
        <NavigationMenu mobile={width < 768} show={showMobileMenu} />
        {width < 768 && (
          <Button
            type="link"
            className="btn-hamburger"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <FontAwesomeIcon
              icon={showMobileMenu ? faTimes : faBars}
              size="lg"
            />
          </Button>
        )}
      </div>
    </header>
  );
};
