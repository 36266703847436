import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faBookmark } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../../services/theme';
import './screen-select.less';

const ScreenSelect = ({ selectScreen, screenLabels }) => (
  <div className="screen-select">
    <div className="select-card primary">
      <h2>
        <FontAwesomeIcon icon={faCalculator} style={{ marginRight: 10 }} />{' '}
        Select Your Calculator
      </h2>
      <p>
        Calculate Business Interruption quickly and accurately with our
        calculators individually tailored to the policy wording.
      </p>
      <Button
        size="large"
        className="link-btn"
        onClick={() => selectScreen(screenLabels.Calculators)}
      >
        START NOW
      </Button>
    </div>
    {theme.isUsTheme && (
      <div className="select-card">
        <h2>
          <FontAwesomeIcon icon={faBookmark} style={{ marginRight: 10 }} /> Your
          Saved Calculations
        </h2>
        <p>
          Find saved calculations that you saved for later and edit, download or
          share with your client.
        </p>
        <Button
          size="large"
          type="primary"
          className="link-btn"
          onClick={() => selectScreen(screenLabels.SavedCalculations)}
        >
          VIEW SAVED
        </Button>
      </div>
    )}
  </div>
);

export default ScreenSelect;
