import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import auth from '../../../../services/auth';
import UserActions from '../user-actions/UserActions';
import {
  selectIsUserClient,
  selectIsUserAdmin,
} from '../../../../features/auth';
import theme, { THEMES } from '../../../../services/theme';
import { SCREEN_LABELS } from '../../../../scenes/calculators/Calculators';

export default ({ mobile, show }) => {
  const isUserClient = useSelector(selectIsUserClient);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const adminModuleUrl = theme.isUsTheme
    ? 'https://usaadmin.bicalculator.com/Admin/Calculators'
    : 'https://admin.bicalculator.com/Admin/Calculators';
  return (
    <nav
      className={`site-header-nav-container ${mobile ? 'mobile' : ''} ${
        show ? 'open' : ''
      }`}
    >
      <ul className="site-header-nav">
        {auth.isLoggedIn && !isUserClient && theme.isUsTheme && (
          <li className="site-header-nav-item">
            <Link to="/calculators" state={{ screen: SCREEN_LABELS.Select }}>
              HOME
            </Link>
          </li>
        )}
        {auth.isLoggedIn && !isUserClient && (
          <li className="site-header-nav-item">
            <Link
              to="/calculators"
              state={{ screen: SCREEN_LABELS.Calculators }}
            >
              CALCULATOR LIST
            </Link>
          </li>
        )}
        {auth.isLoggedIn && !isUserClient && theme.isUsTheme && (
          <li className="site-header-nav-item">
            <Link
              to="/calculators"
              state={{ screen: SCREEN_LABELS.SavedCalculations }}
            >
              SAVED CALCULATIONS
            </Link>
          </li>
        )}
        {auth.isLoggedIn && isUserAdmin && (
          <li className="site-header-nav-item">
            <a href={adminModuleUrl} target="_blank" rel="noreferrer noopener">
              ADMINISTRATION
            </a>
          </li>
        )}
        {theme.siteTheme !== THEMES.aviva && (
          <li className="site-header-nav-item">
            <a
              href="https://biexplained.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              BI EXPLAINED
            </a>
          </li>
        )}

        {theme.siteTheme === THEMES.santam && (
          <li className="site-header-nav-item">
            <a
              href="https://www.youtube.com/watch?v=jXLW9O4PXnE&feature=youtu.be"
              target="_blank"
              rel="noreferrer noopener"
            >
              SITE TOUR
            </a>
          </li>
        )}
      </ul>
      <UserActions />
    </nav>
  );
};
