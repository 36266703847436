import { AES, enc, mode, pad } from 'crypto-js';

const KEY = process.env.REACT_APP_AES_SECRET;
const VECTOR = process.env.REACT_APP_AES_VECTOR;

const key = enc.Utf8.parse(KEY);
const vector = enc.Utf8.parse(VECTOR);

export const encrypt = (value) => {
  try {
    if (value) {
      const encrypted = AES.encrypt(enc.Utf8.parse(value), key, {
        iv: vector,
        mode: mode.CBC,
        padding: pad.Pkcs7,
      }).toString();
      return encrypted;
    }
    return value;
  } catch {
    return value;
  }
};

export const decrypt = (encrypted) => {
  try {
    const decrypted = AES.decrypt(encrypted, key, {
      iv: vector,
      mode: mode.CBC,
      padding: pad.Pkcs7,
    }).toString(enc.Utf8);
    if (decrypted) {
      return decrypted;
    }
    return encrypted;
  } catch {
    return encrypted;
  }
};
