/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Select, Button, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  fetchCountries,
  fetchBuyerGroups,
} from '../../../../features/calculators';
import './admin-section.less';
import theme from '../../../../services/theme';

const AdminSection = ({
  fetchCalculatorList,
  fetchResultsSummary,
  calcListFilter,
  setCalcListFilter,
  loading,
}) => {
  const defaultCountry = theme.isUsTheme ? 9 : 1; // 9: USA, 1: Australia
  const [countries, setCountries] = useState([]);
  const [buyerGroups, setBuyerGroups] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [selectedBuyerGroup, setSelectedBuyerGroup] = useState();

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setSelectedBuyerGroup();
  };

  const handleRemoveFilter = () => {
    if (selectedBuyerGroup || selectedBuyerGroup === 0) {
      setSelectedBuyerGroup();
      fetchCalculatorList(defaultCountry);
    }
  };

  const handleBuyerGroupChange = (value) => {
    setSelectedBuyerGroup(value);
    fetchCalculatorList(selectedCountry, value);
  };

  const handleFilterChange = (value) => {
    const values = { ...calcListFilter };
    values[value.target.name] = value.target.checked;
    setCalcListFilter(values);
  };

  useEffect(() => {
    const fetchCountriesAsync = async () => {
      const resCountries = await fetchCountries();
      if (resCountries) {
        const countryOption = resCountries.map((c) => ({
          label: c.CountryName,
          value: c.ID,
        }));
        setCountries(countryOption);
      }
    };

    fetchCountriesAsync();
  }, []);

  useEffect(() => {
    const fetchBuyerGroupsAsync = async () => {
      const resBuyerGroup = await fetchBuyerGroups(selectedCountry);
      if (resBuyerGroup && Array.isArray(resBuyerGroup)) {
        const buyerGroupOption = resBuyerGroup.map((b) => ({
          label: b.BuyerGroup,
          value: b.Id,
        }));
        setBuyerGroups(buyerGroupOption);
      }
    };
    fetchBuyerGroupsAsync();
    fetchCalculatorList(selectedCountry);
    fetchResultsSummary(selectedCountry);
  }, [selectedCountry]);

  return (
    <div className="admin-section">
      <div className="admin-section-title">Admin Section</div>
      <div className="admin-section-select">
        <div className="admin-section-select-input">
          Country :
          <Select
            defaultValue={selectedCountry}
            showSearch
            style={{ width: 220, marginLeft: 20 }}
            onChange={handleCountryChange}
            disabled={loading}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={countries}
          />
        </div>
        {!!buyerGroups.length && (
          <div className="admin-section-select-input">
            BuyerGroup :
            <Select
              style={{ width: 220, marginLeft: 20 }}
              onChange={handleBuyerGroupChange}
              showSearch
              disabled={loading}
              loading={loading}
              value={selectedBuyerGroup}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={buyerGroups}
            />
            {(!!selectedBuyerGroup || selectedBuyerGroup === 0) && (
              <Button
                disabled={loading}
                type="link"
                size="small"
                title="Remove buyer group"
                onClick={handleRemoveFilter}
                icon={<FontAwesomeIcon icon={faTimes} />}
              />
            )}
          </div>
        )}
        <div className="admin-section-select-input">
          <Checkbox
            name="showArchived"
            onChange={handleFilterChange}
            className="calc-filter-checkbox"
            checked={calcListFilter.showArchived}
          />
          Show Archived
          <Checkbox
            name="showInActive"
            onChange={handleFilterChange}
            className="calc-filter-checkbox"
            checked={calcListFilter.showInActive}
          />
          Show InActive
        </div>
      </div>
    </div>
  );
};

export default AdminSection;
