import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tooltip, Button, Empty } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faFilePdf,
  faEnvelope,
  faPlay,
  faExclamationTriangle,
  faCalculator,
} from '@fortawesome/free-solid-svg-icons';
import useWindowResize from '../../../../components/hooks/useWindowResize';
import EmailModal from '../email-modal/EmailModal';
import SelectCalculatorModal from '../select-calculator-modal/SelectCalculatorModal';
import Download from '../../../../components/download/Download';
import theme from '../../../../services/theme';

import './calculator-list.less';
import {
  fetchDataInputPdf,
  disableCalculationResult,
} from '../../../../features/calculators';

export const stringSorter = (a, b) => {
  const stringA = a?.toUpperCase() || '';
  const stringB = b?.toUpperCase() || '';
  if (stringA < stringB) {
    return -1;
  }
  if (stringA > stringB) {
    return 1;
  }
  return 0;
};

const CalculatorList = ({
  calculatorList,
  handleMultiCalculators,
  loading,
  userCountryId,
  buyGroupId,
  pagination = false,
}) => {
  const [emailState, setEmailState] = useState({
    visible: false,
    calculatorId: null,
    oldCalculatorId: null,
  });

  const [calculatorSelectModalState, setCalculatorSelectModalState] = useState({
    visible: false,
    samePolicyCalculators: [],
  });

  const { isMobile } = useWindowResize();

  const dataSource = (calculatorList) =>
    calculatorList
      ? calculatorList.map(
          ({
            CalculatorId,
            OldCalculatorId,
            DocumentPath,
            InsurerName,
            IsPolicyArchived,
            PolicyCode,
            PolicyDate,
            PolicyName,
            MultiCalculatorPolicy,
            SamePolicyCalculators,
            CalculatorPath,
            IsActive,
            QuestionsPdfPath,
            CalculatorName,
          }) => ({
            key: CalculatorId,
            active: IsActive,
            insurer: InsurerName,
            policy: {
              CalculatorName,
              PolicyName,
              PolicyCode,
              PolicyDate,
              IsPolicyArchived,
            },
            pdf: { DocumentPath, QuestionsPdfPath, CalculatorId },
            email: {
              CalculatorId,
              OldCalculatorId,
              SamePolicyCalculators,
            },
            action: {
              CalculatorId,
              SamePolicyCalculators,
              CalculatorPath,
              Action:
                MultiCalculatorPolicy && SamePolicyCalculators?.length > 0
                  ? 'SELECT'
                  : 'ENTER',
              PolicyInfo: { InsurerName, PolicyName, PolicyCode },
            },
          })
        )
      : [];

  const columns = [
    {
      title: 'Insurer',
      dataIndex: 'insurer',
      key: 'insurer',
      width: '25%',
      sorter: (a, b) => stringSorter(a.insurer, b.insurer),
      filters: [
        ...new Set(calculatorList?.map(({ InsurerName }) => InsurerName)),
      ].map((value) => ({
        text: value,
        value,
      })),
      onFilter: (value, record) => record.insurer === value,
      filterSearch: true,
    },
    {
      title: 'Policy',
      dataIndex: 'policy',
      key: 'policy',
      width: isMobile ? '33%' : '45%',
      sorter: (a, b) => stringSorter(a.policy.PolicyName, b.policy.PolicyName),
      filters: [
        ...new Set(calculatorList?.map(({ PolicyName }) => PolicyName)),
      ].map((value) => ({
        text: value,
        value,
      })),
      onFilter: (value, record) => record.policy.PolicyName === value,
      filterSearch: true,
      render: ({
        IsPolicyArchived,
        PolicyCode,
        PolicyDate,
        PolicyName,
        CalculatorName,
      }) => (
        <>
          <Tooltip
            title={`${
              IsPolicyArchived ? 'ARCHIVED' : ''
            } PolicyCode: ${PolicyCode} (${PolicyDate})`}
          >
            {!IsPolicyArchived ? (
              <FontAwesomeIcon icon={faInfoCircle} />
            ) : (
              <FontAwesomeIcon icon={faExclamationTriangle} color="#c9302c" />
            )}
          </Tooltip>
          &nbsp;&nbsp;
          {CalculatorName || PolicyName}
        </>
      ),
    },
    {
      title: 'PDF',
      dataIndex: 'pdf',
      key: 'pdf',
      width: isMobile ? '12%' : '8%',
      align: 'center',
      render: ({ DocumentPath, QuestionsPdfPath, CalculatorId }) => (
        <>
          <Tooltip title="Click to view policy">
            <Button
              type="link"
              href={DocumentPath}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </Button>
          </Tooltip>

          {theme.isUsTheme && (
            <Download
              onDownload={() => fetchDataInputPdf(CalculatorId)}
              fileName={`${QuestionsPdfPath.split('/')[1] || QuestionsPdfPath}`}
            >
              {(download, downloading) => (
                <Tooltip title="Click to download datainput">
                  <Button type="link" onClick={download} loading={downloading}>
                    <FontAwesomeIcon icon={faCalculator} />
                  </Button>
                </Tooltip>
              )}
            </Download>
          )}
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: isMobile ? '15%' : '10%',
      align: 'center',
      render: ({ CalculatorId, OldCalculatorId, SamePolicyCalculators }) => (
        <Tooltip title="Click to email calculator">
          <FontAwesomeIcon
            icon={faEnvelope}
            onClick={() => {
              if (SamePolicyCalculators && SamePolicyCalculators.length > 0) {
                setCalculatorSelectModalState({
                  visible: true,
                  samePolicyCalculators: SamePolicyCalculators,
                });
              } else {
                setEmailState({
                  visible: true,
                  calculatorId: CalculatorId,
                  oldCalculatorId: OldCalculatorId,
                });
              }
            }}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      ),
    },
    {
      title: !isMobile && 'Action',
      dataIndex: 'action',
      width: isMobile ? '15%' : '12%',
      align: 'center',
      key: 'action',
      render: (action) =>
        action.Action === 'SELECT' ? (
          <Button
            type="primary"
            size={isMobile && 'small'}
            onClick={() => handleMultiCalculators(action.CalculatorId)}
          >
            <FontAwesomeIcon
              icon={faPlay}
              style={{ marginRight: !isMobile && 5 }}
            />{' '}
            {!isMobile && action.Action}
          </Button>
        ) : (
          <Link to={action.CalculatorPath}>
            <Button type="primary" size={isMobile && 'small'}>
              <FontAwesomeIcon
                icon={faPlay}
                style={{ marginRight: !isMobile && 5 }}
              />{' '}
              {!isMobile && action.Action}
            </Button>
          </Link>
        ),
    },
  ];
  return (
    <>
      <SelectCalculatorModal
        calculators={calculatorSelectModalState.samePolicyCalculators}
        visible={calculatorSelectModalState.visible}
        setEmailState={setEmailState}
        onClose={() => setCalculatorSelectModalState({ visible: false })}
      />
      <EmailModal
        type="client"
        visible={emailState.visible}
        onSend={() => setEmailState({ visible: false })}
        onClose={async (refId) => {
          if (refId) {
            await disableCalculationResult(refId);
          }
          setEmailState({ visible: false });
        }}
        calculatorId={emailState.calculatorId}
        oldCalculatorId={emailState.oldCalculatorId}
        userCountryId={userCountryId}
        buyGroupId={buyGroupId}
      />
      <Table
        locale={{
          emptyText: (
            <div style={{ padding: '30px' }}>
              {calculatorList !== null && calculatorList?.length < 1 && (
                <Empty />
              )}
            </div>
          ),
        }}
        loading={loading}
        sticky
        bordered
        showSorterTooltip={false}
        pagination={pagination}
        dataSource={dataSource(calculatorList)}
        columns={columns}
        rowClassName={(record) => !record.active && 'inactive'}
      />
    </>
  );
};

export default CalculatorList;
