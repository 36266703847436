import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { message } from 'antd';
import store from './store';
import './index.css';
import App from './components/app/App';
import Routes from './routes';

// import reportWebVitals from "./reportWebVitals";

message.config({
  duration: 5,
  maxCount: 3,
});

ReactDOM.render(
  <Provider store={store}>
    <App>
      <Routes />
    </App>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
