import partnersandLeftImg from './partnersand/BI-advBanner-left-partners&.png';
import partnersandRightImg from './partnersand/BI-advBanner-right-partners&.png';
import santamLeftImg from './santam/Santam_Side_banner_link_left.jpg';
import santamRightImg from './santam/Santam_Side_banner_link_right.jpg';
import axaLeftImg from './axa/axa-BI-calculator-left-banner.jpg';
import axaRightImg from './axa/axa-BI-calculator-right-banner.jpg';

// Australia: 1
// Canada: 7
// Fiji: 56
// Hong Kong: 11
// Indonesia: 5
// Ireland: 10
// Malaysia: 12
// Namibia: 104
// New Zealand: 2
// Papua New Guinea: UNKNOWN
// Singapore: 8
// South Africa: 6
// Thailand: 137
// United Kingdom: 3
// USA: 9

export default [
  {
    key: 'lmi-legal',
    country: 1,
    leftImage:
      'https://lmicdn.blob.core.windows.net/common/LMILegal-banner.jpg',
    rightImage:
      'https://lmicdn.blob.core.windows.net/common/LMILegal-banner.jpg',
    url: 'https://lmilegal.com/',
  },
  {
    key: 'lmi-responsible-manager-course',
    country: 1,
    leftImage:
      'https://lmicdn.blob.core.windows.net/common/LMICollege-ResponsibleManager-banner.jpg',
    rightImage:
      'https://lmicdn.blob.core.windows.net/common/LMICollege-ResponsibleManager-banner.jpg',
    url: 'https://lmicollege.edu.au/responsible-manager/',
  },
  {
    key: 'lmi-webinar',
    country: 1,
    leftImage:
      'https://lmicdn.blob.core.windows.net/common/webinar_banner_2024.jpg',
    rightImage:
      'https://lmicdn.blob.core.windows.net/common/webinar_banner_2024.jpg',
    url: 'https://lmigroup.io/webinars',
  },
  {
    key: 'lmi-claims',
    country: 1,
    leftImage:
      'https://lmicdn.blob.core.windows.net/common/lmi-banner-claims.png',
    rightImage:
      'https://lmicdn.blob.core.windows.net/common/lmi-banner-claims.png',
    url: 'http://www.lmigroup.com/content.aspx?catId=148&hideNavigation=true',
  },
  {
    key: 'lmi-college-eservices',
    country: 1,
    leftImage:
      'https://lmicdn.blob.core.windows.net/common/lmi-college-eservices-banner-01.jpg',
    rightImage:
      'https://lmicdn.blob.core.windows.net/common/lmi-college-eservices-banner-02.jpg',
    url: 'https://www.thefss.com.au/about-us.html',
  },
  {
    key: 'lmi-custom-reporting',
    country: 1,
    leftImage:
      'https://lmicdn.blob.core.windows.net/common/RC_CustomReporting_Vertical.png',
    rightImage:
      'https://lmicdn.blob.core.windows.net/common/RC_CustomReporting_Vertical.png',
    url: 'https://lmigroup.io/riskcoach-tailored-reporting-now-available/',
  },
  {
    key: 'lmi-claims-nz',
    country: 2,
    leftImage:
      'https://lmicdn.blob.core.windows.net/common/lmi-banner-claims.png',
    rightImage:
      'https://lmicdn.blob.core.windows.net/common/lmi-banner-claims.png',
    url: 'http://www.lmigroup.com/content.aspx?catId=148&hideNavigation=true',
  },
  {
    key: 'lmi-custom-reporting-nz',
    country: 2,
    leftImage:
      'https://lmicdn.blob.core.windows.net/common/RC_CustomReporting_Vertical.png',
    rightImage:
      'https://lmicdn.blob.core.windows.net/common/RC_CustomReporting_Vertical.png',
    url: 'https://lmigroup.io/riskcoach-tailored-reporting-now-available/',
  },
];

export const partnersandAds = [
  {
    key: 'partnersand-side-ads',
    country: 3,
    leftImage: partnersandLeftImg,
    rightImage: partnersandRightImg,
    url: 'https://www.partnersand.com/',
  },
];

export const santamAds = [
  {
    key: 'santam-side-ads',
    country: 6,
    leftImage: santamLeftImg,
    rightImage: santamRightImg,
    url: 'https://www.santam.co.za/',
  },
];

export const avivaAds = [];
export const usAds = [];
export const axaAds = [
  {
    key: 'axa-side-ads',
    country: 3,
    leftImage: axaLeftImg,
    rightImage: axaRightImg,
    url: 'https://www.axaconnect.co.uk/commercial-lines/axa-vantage-mid-market/?utm_source=advert&utm_medium=website',
  },
];
