/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';

import { Layout } from '../../../../components';
import { fetchCalculatorReferenceAsync } from '../../calculatorsSlice';
import { addStats } from '../../../../features/calculators';
import stats from '../../../../services/stats';
import {
  useAnalyticsPageviewTracker,
  useAnalyticsEventTracker,
} from '../../../../components/tracker/Tracker';

import './calculator-references.less';

const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const CalcalatorReferences = () => {
  const { calcId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageviewTracker = useAnalyticsPageviewTracker();
  const eventTracker = useAnalyticsEventTracker('CalculatorSelect');
  const [loadingReference, setLoadingReference] = useState(true);

  const calculatorReference = useSelector(
    (state) => state.calculators.calculatorReference
  );

  useEffect(() => {
    const fetchCalculatorRef = async () => {
      await dispatch(fetchCalculatorReferenceAsync(calcId));
    };
    fetchCalculatorRef();
    setLoadingReference(false);
    addStats({
      projectId: PROJECT_ID,
      typeName: stats.statLabels.calculatorStarted,
    });
    pageviewTracker(`/calculators/${calcId}/referencenumber`);
  }, [calcId]);

  useEffect(() => {
    if (
      !loadingReference &&
      calculatorReference &&
      calculatorReference?.ReferenceId &&
      calculatorReference?.FirstStepId
    ) {
      eventTracker(
        'Select',
        `${calculatorReference.PolicyId}-${calculatorReference.PolicyName}-${calculatorReference.PolicyCode}`
      );
      navigate(
        `/references/${calculatorReference.ReferenceId}/steps/${calculatorReference.FirstStepId}`
      );
    }
  }, [calculatorReference]);

  return (
    <Layout>
      <div className="loading-container">
        <Spin tip="Loading calculator..." size="large" />
      </div>
    </Layout>
  );
};

export default CalcalatorReferences;
