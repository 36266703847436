/* eslint-disable react/jsx-boolean-value */
import { Modal, Button } from 'antd';
import cx from 'classnames';
import theme from '../../../../services/theme';
import './select-calculator-modal.less';

const SelectCalculatorModal = ({
  visible,
  onClose,
  calculators,
  setEmailState,
}) => (
  <Modal
    title="Select Calculator to Email"
    wrapClassName={cx('select-calculator-modal', {
      [theme.siteTheme]: theme.siteTheme,
    })}
    visible={visible}
    destroyOnClose={true}
    onCancel={onClose}
    width="60%"
    footer={
      <Button type="default" onClick={onClose}>
        Close
      </Button>
    }
  >
    {calculators &&
      calculators.map(
        ({
          CalculatorId,
          OldCalculatorId,
          MultiCalculatorPolicyName,
          MultiCalculatorPolicyDescription,
        }) => (
          <div className="multi-calculator">
            <h2>{MultiCalculatorPolicyName}</h2>
            <p>{MultiCalculatorPolicyDescription}</p>
            <Button
              type="primary"
              onClick={() => {
                setEmailState({
                  visible: true,
                  calculatorId: CalculatorId,
                  oldCalculatorId: OldCalculatorId,
                });
                onClose();
              }}
            >
              EMAIL
            </Button>
          </div>
        )
      )}
  </Modal>
);

export default SelectCalculatorModal;
