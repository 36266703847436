/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { Layout } from '../../../../components';
import { logout } from '../../../../features/auth';
import auth from '../../../../services/auth';
import './logout-page.less';

const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [auth.isLoggedIn]);

  return (
    <Layout>
      <div className="loading-container">
        <Spin tip="navigating to homepage..." size="large" />
      </div>
    </Layout>
  );
};

export default LogoutPage;
