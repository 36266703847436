/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Spin, Image } from 'antd';
import { useDispatch, connect } from 'react-redux';
import { fetchBannerAsync } from '../../features/ads';
import theme, { THEMES } from '../../services/theme';
import './ads.less';

const ADS_EXCLUDED_THEMES = [THEMES.aviva];

const Ads = ({ media, user, adsLoading }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !media) {
      dispatch(
        fetchBannerAsync({ userId: user.userId, bannerId: user.bannerId })
      );
    }
  }, [user, media]);

  if (ADS_EXCLUDED_THEMES.includes(theme.siteTheme)) {
    return null;
  }

  return (
    <div className="media-ads">
      {adsLoading ? (
        <Spin />
      ) : (
        media && <Image src={media.FilePath} preview={false} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  media: state.ads.media,
  adsLoading: state.ads.adsStatus === 'loading',
  user: state.auth?.user?.user,
});

export default connect(mapStateToProps)(Ads);
