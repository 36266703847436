/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import { Button, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faShare, faUpload } from '@fortawesome/free-solid-svg-icons';

import { updateCalculationDescription } from '../../../../features/calculators';

const CalculationLabel = ({ value, record }) => {
  const inputRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [editable, setEditable] = useState(false);
  const [description, setDescription] = useState(value);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus({
        cursor: 'end',
      });
    }
  }, [editable]);

  const handleEdit = () => {
    setEditable(true);
  };

  const handleUpdate = async () => {
    if (description !== value) {
      setLoading(true);
      await updateCalculationDescription({
        refId: record.referenceId,
        description,
      });
      setLoading(false);
      await record.fetchResultsSummary();
    }
    setEditable(false);
  };

  const onChange = (e) => {
    setDescription(e.target.value.trim());
  };

  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {(value || editable || visible) && (
        <>
          {editable ? (
            <div className="calculation-label">
              <Input
                size="small"
                defaultValue={value}
                placeholder="Please enter your description here"
                onChange={onChange}
                ref={inputRef}
                onKeyPress={(e) => e.key === 'Enter' && handleUpdate()}
              />

              <Button
                type="text"
                size="small"
                onClick={handleUpdate}
                title="Click to confirm"
                loading={loading}
              >
                {value === description ? (
                  <FontAwesomeIcon icon={faShare} />
                ) : (
                  <FontAwesomeIcon icon={faUpload} />
                )}
              </Button>
            </div>
          ) : (
            <div
              className="calculation-label"
              aria-hidden="true"
              onClick={handleEdit}
              onKeyDown={handleEdit}
            >
              <span>{value}</span>
              {visible && (
                <Button
                  type="text"
                  size="small"
                  onClick={handleEdit}
                  title="Click to update description"
                >
                  <FontAwesomeIcon icon={faPen} />
                </Button>
              )}
            </div>
          )}
        </>
      )}
      {!value && <div className="summary-values">{record.values}</div>}
    </div>
  );
};

export default CalculationLabel;
