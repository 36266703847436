/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

const GA3_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const Tracker = ({ currentStepNumber, calculatorResults, userId }) => {
  const location = useLocation();

  if (GA3_TRACKING_ID) {
    ReactGA.initialize(GA3_TRACKING_ID, {
      testMode: process.env.NODE_ENV === 'test',
      gaOptions: {
        cookieFlags: 'secure;samesite=none',
      },
    });
  }

  if (GA4_MEASUREMENT_ID) {
    ReactGA4.initialize(GA4_MEASUREMENT_ID, {
      gaOptions: {
        testMode: process.env.NODE_ENV === 'test',
        cookieFlags: 'secure;samesite=none',
        userId,
        clientId: userId,
      },
    });
  }

  useEffect(() => {
    if (userId) {
      ReactGA.set({ dimension1: userId });
      ReactGA4.gtag('set', 'user_properties', { userId });
    }
  }, [userId]);

  useEffect(() => {
    if (location.pathname === '/') {
      ReactGA.pageview(`/`);
      ReactGA4.send({ hitType: 'pageview', page: '/', title: 'home' });
    }
    if (location.pathname === '/calculators') {
      ReactGA.pageview(`/selectcalculator`);
      ReactGA4.send({
        hitType: 'pageview',
        page: '/selectcalculator',
        title: 'selectcalculator',
      });
    }
  }, [location]);

  useEffect(() => {
    if (currentStepNumber) {
      ReactGA.pageview(`/calculatorstep${currentStepNumber}`);
      ReactGA4.send({
        hitType: 'pageview',
        page: `/calculatorstep${currentStepNumber}`,
        title: `/calculatorstep${currentStepNumber}`,
      });
    }
  }, [currentStepNumber]);

  useEffect(() => {
    if (calculatorResults?.length > 0) {
      ReactGA.pageview('/results');
      ReactGA4.send({
        hitType: 'pageview',
        page: `/results`,
        title: `results`,
      });
    }
  }, [calculatorResults]);

  return null;
};

// category: User | CalculatorSelect | Download | Email
// action: Login | Select | ResultDownload | ResultEmail | ClientLink
// label: NewLinkgen | ClientLink | ResultDownload | ResultEmail | ${PolicyId}-${PolicyName}-${PolicyCode}

export const useAnalyticsEventTracker = (category = 'User') => {
  const eventTracker = (action, label) => {
    ReactGA.event({ category, action, label });
    ReactGA4.event({
      category,
      action,
      label,
    });
  };
  return eventTracker;
};

export const useAnalyticsPageviewTracker = () => {
  const pageviewTracker = (pathname) => {
    ReactGA.pageview(pathname);
    ReactGA4.send({
      hitType: 'pageview',
      page: pathname,
      title: pathname,
    });
  };
  return pageviewTracker;
};

const mapStateToProps = (state) => ({
  calculatorReference: state.calculators.calculatorReference,
  currentStepNumber: state.calculators.calculatorStep?.currentStepNumber,
  calculatorResults: state.calculators.calculatorResults,
  userId: state.auth?.user?.user?.userId,
});

export default connect(mapStateToProps)(Tracker);
