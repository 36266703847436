/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Row, Col } from 'antd';
import EmailModal from '../email-modal/EmailModal';
import SavedCalculationTable from './SavedCalculationsTable';
import { selectResultSummaryByCalculator } from '../../calculatorsSlice';
import {
  disableCalculationResult,
  updateViewedReferences,
} from '../../../../features/calculators';
import { decrypt } from '../../../../services/crypto';

import './saved-calculations.less';

const { Search } = Input;

const SavedCalculations = ({ loading, fetchResultsSummary }) => {
  const lastDuplicatedCalculation = useSelector(
    (state) => state.calculators?.lastDuplicatedCalculation
  );
  const resultsSummary = useSelector((state) =>
    selectResultSummaryByCalculator(state)
  );
  const updatedReferencesByClient = useSelector(
    (state) => state.calculators.resultsSummary?.ClientUpdatedResults
  );
  const [dataSource, setDataSource] = useState();
  const [emailModalState, setEmailModalState] = useState({
    visible: false,
    refId: null,
  });

  const parsedResultsSummary = useMemo(
    () =>
      resultsSummary &&
      resultsSummary.map(
        ({
          parentGroupId,
          clientName,
          insurer,
          calculatorName,
          resultsSummary,
          lastUpdate,
          updatedByClient,
        }) => ({
          key: `${parentGroupId}-${clientName}-${calculatorName}`,
          clientName: clientName === 'null' ? '' : clientName,
          insurer,
          lastUpdate,
          calculatorName,
          resultsSummary,
          updatedByClient,
        })
      ),
    [resultsSummary]
  );

  useEffect(() => {
    if (parsedResultsSummary) {
      setDataSource(parsedResultsSummary);
    }
  }, [parsedResultsSummary, lastDuplicatedCalculation]);

  const onKeywordSearch = (value) => {
    if (value.length > 1) {
      const filtered = parsedResultsSummary.filter(
        ({ calculatorName, clientName, insurer }) =>
          `${calculatorName}${decrypt(clientName)}${insurer}`
            .toLowerCase()
            .includes(value.toLowerCase())
      );
      setDataSource(filtered);
    } else {
      setDataSource(parsedResultsSummary);
    }
  };

  return (
    <>
      <EmailModal
        visible={emailModalState.visible}
        onClose={async (refId) => {
          if (refId && emailModalState?.status === 2) {
            await disableCalculationResult(refId);
          }
          setEmailModalState({ visible: false });
        }}
        calculationEmailParams={emailModalState}
        referenceId={emailModalState.referenceId}
        callback={fetchResultsSummary}
        type="share"
      />
      <Row className="saved-calculations-search">
        <Col md={18} xs={24}>
          <h3>Saved Calculations</h3>
        </Col>
        <Col md={6} xs={24}>
          <Search
            placeholder="Search by keyword..."
            onSearch={onKeywordSearch}
          />
        </Col>
      </Row>
      <SavedCalculationTable
        dataSource={dataSource}
        loading={loading}
        updateViewedReferences={updateViewedReferences}
        updatedReferencesByClient={updatedReferencesByClient}
        setEmailModalState={setEmailModalState}
        fetchResultsSummary={fetchResultsSummary}
      />
    </>
  );
};

export default SavedCalculations;
