/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-boolean-value */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Form, Input, Button, Skeleton, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare, faAt } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import TextEditor from '../../../../components/text-editor/TextEditor';
import useWindowResize from '../../../../components/hooks/useWindowResize';
import { useAnalyticsEventTracker } from '../../../../components/tracker/Tracker';
import theme from '../../../../services/theme';
import { InsuredNameInput } from '../../scenes/calculator/components/insured-name-input/InsuredNameInput';
import stats from '../../../../services/stats';
import './email-modal.less';

import {
  fetchClientEmail,
  fetchResultEmail,
  fetchCalculationEmail,
  sendCalculatoionEmail,
  addStats,
} from '../../../../features/calculators';
import { encrypt } from '../../../../services/crypto';

const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const EmailModal = ({
  visible,
  onClose,
  calculatorId,
  oldCalculatorId,
  userCountryId,
  buyGroupId,
  referenceId,
  calculationEmailParams,
  type,
  callback,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [templateValue, setTemplateValue] = useState();
  const insuredName = useSelector((state) => state.calculators.insuredName);
  const eventTracker = useAnalyticsEventTracker('Email');
  const { isMobile } = useWindowResize();

  useEffect(() => {
    setSendingEmail(false);
    setLoading(true);

    if (type === 'client' && visible && calculatorId) {
      const fetchClientEmailTemplate = async () => {
        const emailData = await fetchClientEmail({
          calculatorId,
          oldCalculatorId,
          userCountryId,
          buyGroupId,
        });
        setTemplateValue(emailData);
        setLoading(false);
      };
      fetchClientEmailTemplate();
    }

    if (
      type === 'share' &&
      visible &&
      (referenceId || calculationEmailParams?.refId)
    ) {
      const fetchCalculationEmailTemplate = async () => {
        const emailData = await fetchCalculationEmail({
          ...calculationEmailParams,
        });
        setTemplateValue(emailData);
        setLoading(false);
      };
      fetchCalculationEmailTemplate();
    }

    if (type === 'result' && visible && referenceId) {
      const fetchResultEmailTemplate = async () => {
        const emailData = await fetchResultEmail({
          refId: referenceId,
          insuredName,
        });
        setTemplateValue(emailData);
        setLoading(false);
      };
      fetchResultEmailTemplate();
    }
  }, [
    visible,
    calculatorId,
    referenceId,
    oldCalculatorId,
    type,
    userCountryId,
    buyGroupId,
    calculationEmailParams,
  ]);

  const handleSendEmail = async (values) => {
    try {
      setSendingEmail(true);
      if (type === 'share' || type === 'client') {
        // EmailType 1 to save the recepient email
        await sendCalculatoionEmail({
          values: {
            ...values,
            InsuredName: encrypt(values.InsuredName),
            EmailTo: encrypt(values.EmailTo),
            EmailType: 1,
          },
          referenceId: templateValue?.ResultReferenceId || referenceId,
        });
      } else {
        await sendCalculatoionEmail({
          values: {
            ...values,
            EmailTo: encrypt(values.EmailTo),
          },
          referenceId: templateValue?.ResultReferenceId || referenceId,
        });
      }
      setSendingEmail(false);
      onClose();

      const typeName =
        type === 'result'
          ? stats.statLabels.resultEmail
          : stats.statLabels.clientLink;
      addStats({
        projectId: +PROJECT_ID,
        typeName,
      });
      eventTracker(typeName, typeName);
      notification.success({
        key: 'sending_success',
        message: 'Email has been sent',
      });
    } catch (err) {
      onClose();
      notification.error({ key: 'sending_fail', message: 'Email failed' });
    }
  };

  return (
    <Modal
      title="Compose Email"
      afterClose={callback}
      closeIcon={false}
      keyboard={false}
      maskClosable={false}
      closable={false}
      wrapClassName={cx('send-email-modal', {
        [theme.siteTheme]: theme.siteTheme,
      })}
      visible={visible}
      destroyOnClose={true}
      onCancel={() => onClose(templateValue?.ResultReferenceId)}
      width={isMobile ? '90%' : '60%'}
      footer={
        <>
          <Button
            type="default"
            onClick={() => onClose(templateValue?.ResultReferenceId)}
            disabled={sendingEmail || loading}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            loading={sendingEmail}
            disabled={sendingEmail || loading}
            onClick={() => {
              form.validateFields().then((values) => {
                form.resetFields();
                handleSendEmail({
                  ...templateValue,
                  ...values,
                });
              });
            }}
          >
            Send Email
          </Button>
        </>
      }
    >
      {loading ? (
        <Skeleton active paragraph={{ rows: 6 }} />
      ) : (
        <Form name="send-email" form={form} autoComplete="off" preserve={false}>
          <Form.Item
            label="Broker's email (will be added to CC of Email)"
            name="Emailfrom"
            initialValue={templateValue?.EmailFrom}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input valid email address',
              },
            ]}
            className="send-email-form-item"
          >
            <Input
              addonBefore={<FontAwesomeIcon icon={faEnvelopeSquare} />}
              size="large"
              placeholder="broker's email"
            />
          </Form.Item>
          {theme.isUsTheme && type === 'client' && (
            <Form.Item
              label="Insured Name"
              name="InsuredName"
              rules={[
                {
                  required: true,
                  message: 'Please input insured name',
                },
              ]}
              className="send-email-form-item"
            >
              <InsuredNameInput />
            </Form.Item>
          )}
          <Form.Item
            label="Client's Email"
            name="EmailTo"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input valid email address',
              },
            ]}
            className="send-email-form-item"
          >
            <Input
              addonBefore={<FontAwesomeIcon icon={faAt} />}
              size="large"
              placeholder="to"
            />
          </Form.Item>
          <Form.Item
            label="Subject"
            name="Subject"
            rules={[
              {
                required: true,
                message: 'Please input email subject',
              },
            ]}
            className="send-email-form-item"
            initialValue={templateValue?.Subject}
          >
            <Input
              size="large"
              placeholder="subject"
              readOnly={type === 'result'}
            />
          </Form.Item>
          {type === 'result' && (
            <Form.Item
              label="Attachment File Name"
              name="AttachmentFileName"
              initialValue={templateValue?.AttachmentFileName}
              className="send-email-form-item"
            >
              <Input
                size="large"
                placeholder="to"
                readOnly={type === 'result'}
              />
            </Form.Item>
          )}

          <Form.Item
            name="EmailBody"
            noStyle
            rules={[
              {
                required: true,
                message: 'Please input email content',
              },
            ]}
            className="send-email-form-item"
            initialValue={templateValue?.EmailBody}
          >
            <TextEditor />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default EmailModal;
