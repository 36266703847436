import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tooltip, Button, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faFilePdf,
  faEnvelope,
  faPlay,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import useWindowResize from '../../../../components/hooks/useWindowResize';
import EmailModal from '../email-modal/EmailModal';
import { stringSorter } from './CalculatorList';
import './calculator-list.less';

const MultiCalculatorList = ({
  calculatorList,
  userCountryId,
  buyGroupId,
  pagination = false,
}) => {
  const [emailState, setEmailState] = useState({
    visible: false,
    calculatorId: null,
    oldCalculatorId: null,
  });
  const { isMobile } = useWindowResize();

  const dataSource = (calculatorList) =>
    calculatorList
      ? calculatorList.map(
          ({
            CalculatorId,
            OldCalculatorId,
            DocumentPath,
            InsurerName,
            IsPolicyArchived,
            PolicyCode,
            PolicyDate,
            PolicyName,
            SamePolicyCalculators,
            CalculatorPath,
            MultiCalculatorPolicyName,
            MultiCalculatorPolicyDescription,
            IsActive,
          }) => ({
            key: CalculatorId,
            name: MultiCalculatorPolicyName,
            active: IsActive,
            description: {
              PolicyName,
              PolicyCode,
              PolicyDate,
              IsPolicyArchived,
              MultiCalculatorPolicyDescription,
            },
            pdf: DocumentPath,
            email: { CalculatorId, OldCalculatorId },
            action: {
              CalculatorId,
              SamePolicyCalculators,
              CalculatorPath,
              Action: 'ENTER',
              PolicyInfo: { InsurerName, PolicyName, PolicyCode },
            },
          })
        )
      : [];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      sorter: (a, b) =>
        stringSorter(a.MultiCalculatorPolicyName, b.MultiCalculatorPolicyName),
      filters: [
        ...new Set(
          calculatorList?.map(
            ({ MultiCalculatorPolicyName }) => MultiCalculatorPolicyName
          )
        ),
      ].map((value) => ({
        text: value,
        value,
      })),
      onFilter: (value, record) => record.name === value,
      filterSearch: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: isMobile ? '33%' : '45%',
      sorter: (a, b) =>
        stringSorter(
          a.description.MultiCalculatorPolicyDescription,
          b.description.MultiCalculatorPolicyDescription
        ),
      filters: [
        ...new Set(
          calculatorList?.map(
            ({ MultiCalculatorPolicyDescription }) =>
              MultiCalculatorPolicyDescription
          )
        ),
      ].map((value) => ({
        text: value,
        value,
      })),
      onFilter: (value, record) =>
        record.description.MultiCalculatorPolicyDescription === value,
      filterSearch: true,
      render: ({
        IsPolicyArchived,
        PolicyCode,
        PolicyDate,
        MultiCalculatorPolicyDescription,
      }) => (
        <>
          <Tooltip
            title={`${
              IsPolicyArchived ? 'ARCHIVED' : ''
            } PolicyCode: ${PolicyCode} (${PolicyDate})`}
          >
            {!IsPolicyArchived ? (
              <FontAwesomeIcon icon={faInfoCircle} />
            ) : (
              <FontAwesomeIcon icon={faExclamationTriangle} color="#c9302c" />
            )}
          </Tooltip>
          &nbsp;&nbsp;
          {MultiCalculatorPolicyDescription}
        </>
      ),
    },
    {
      title: 'PDF',
      dataIndex: 'pdf',
      key: 'pdf',
      width: isMobile ? '12%' : '8%',
      align: 'center',
      render: (documentLink) => (
        <Tooltip title="Click to view policy">
          <a href={documentLink} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFilePdf} />
          </a>
        </Tooltip>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: isMobile ? '15%' : '10%',
      align: 'center',
      render: (calculator) => (
        <Tooltip title="Click to email calculator">
          <FontAwesomeIcon
            icon={faEnvelope}
            onClick={() =>
              setEmailState({
                visible: true,
                calculatorId: calculator.CalculatorId,
                oldCalculatorId: calculator.OldCalculatorId,
              })
            }
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      ),
    },
    {
      title: !isMobile && 'Action',
      dataIndex: 'action',
      width: isMobile ? '15%' : '12%',
      align: 'center',
      key: 'action',
      render: (action) => (
        <Link to={action.CalculatorPath}>
          <Button type="primary" size={isMobile && 'small'}>
            <FontAwesomeIcon
              icon={faPlay}
              style={{ marginRight: !isMobile && 5 }}
            />{' '}
            {!isMobile && action.Action}
          </Button>
        </Link>
      ),
    },
  ];
  return (
    <>
      <EmailModal
        type="client"
        visible={emailState.visible}
        onSend={() => setEmailState({ visible: false })}
        onClose={() => setEmailState({ visible: false })}
        calculatorId={emailState.calculatorId}
        oldCalculatorId={emailState.oldCalculatorId}
        userCountryId={userCountryId}
        buyGroupId={buyGroupId}
      />
      <Table
        locale={{
          emptyText: (
            <div style={{ padding: '30px' }}>
              <Spin />
            </div>
          ),
        }}
        sticky
        bordered
        showSorterTooltip={false}
        pagination={pagination}
        dataSource={dataSource(calculatorList)}
        columns={columns}
        rowClassName={(record) => !record.active && 'inactive'}
      />
    </>
  );
};

export default MultiCalculatorList;
