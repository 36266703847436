import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faYoutube,
  faFacebookF,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

const Santam = () => (
  <Row>
    <Col className="site-footer-nav-container" xs={14} md={18}>
      <ul className="site-footer-nav">
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/terms-conditions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            TERMS &amp; CONDITIONS
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRIVACY &amp; SECURITY
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://www.axaconnect.co.uk/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            COMPLAINTS
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://www.axaconnect.co.uk/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CONTACT US
          </a>
        </li>
      </ul>
    </Col>
    <Col xs={10} md={6}>
      <ul className="site-footer-social-links">
        <li className="site-footer-social-link-item">
          <a
            href="https://www.facebook.com/SantamInsurance"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </li>
        <li className="site-footer-social-link-item">
          <a
            href="https://twitter.com/SantamInsurance"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </li>
        <li className="site-footer-social-link-item">
          <a
            href="https://www.linkedin.com/company/santam/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <li className="site-footer-social-link-item">
          <a
            href="https://www.youtube.com/SantamInsurance"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </li>
      </ul>
    </Col>
  </Row>
);

export default Santam;
