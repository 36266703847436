import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth';
import calculcatorsReducer from './scenes/calculators/calculatorsSlice';
import adsReducer from './features/ads';
import appReducer from './components/app/appSlice';

const rootReducer = {
  app: appReducer,
  calculators: calculcatorsReducer,
  auth: authReducer,
  ads: adsReducer,
};

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export const getStoreWithPreloadedState = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  });
