/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { message } from 'antd';
import { StatusCodes } from 'http-status-codes';
import api from '../../services/api';
import theme from '../../services/theme';

const BASE_URL = theme.isUsTheme ? api.urls.usaBase : api.urls.base;
const API_LMI_STAT = api.urls.stat;

export const fetchCalculatorList = async ({ countryId, buyerGroupId }) => {
  const timeStamp = new Date().getTime();
  const params = { countryId, buyerGroupId, timeStamp };
  try {
    const resCalculatorList = await axios.get(`${BASE_URL}/calculators`, {
      params,
    });
    return resCalculatorList.data;
  } catch (err) {
    const reason = 'Failed to fetch calculators list';
    message.error(reason);
    throw new Error(reason);
  }
};

export const fetchCalculatorReference = async (id) => {
  try {
    const timeStamp = new Date().getTime();
    const reqUrl = `${BASE_URL}/calculators/${id}/ReferenceNumber?timer=${timeStamp}`;
    const resCalculator = await axios.post(reqUrl);
    return resCalculator.data;
  } catch (err) {
    const reason = 'Failed to fetch calculator references';
    const status = err ? err.status : null;
    message.error(reason);
    switch (status) {
      case StatusCodes.NOT_FOUND:
        window.location.replace('/not-found');
        throw new Error(reason);
      default:
        throw new Error(reason);
    }
  }
};

export const fetchCalculatorReferenceById = async (id) => {
  try {
    const resCalculator = await axios.get(`${BASE_URL}/references/${id}`);
    return resCalculator.data;
  } catch (err) {
    const reason = 'Failed to fetch calculator reference';
    const status = err ? err.status : null;
    message.error(reason);
    switch (status) {
      case StatusCodes.NOT_FOUND:
        window.location.replace('/not-found');
        throw new Error(reason);
      default:
        throw new Error(reason);
    }
  }
};

export const fetchCalculatorStep = async ({ refId, stepId }) => {
  try {
    const timeStamp = new Date().getTime();
    const resCalculatorStep = await axios.get(
      `${BASE_URL}/references/${refId}/steps/${stepId}?timer=${timeStamp}`
    );
    return resCalculatorStep.data;
  } catch (err) {
    const reason = 'Failed to fetch calculator step';
    message.error(reason);
    throw new Error(reason);
  }
};

export const updateCalculatorStep = async ({
  refId,
  stepId,
  values,
  isClient = false,
}) => {
  try {
    const timeStamp = new Date().getTime();
    await axios.post(
      `${BASE_URL}/references/${refId}/steps/${stepId}?timer=${timeStamp}&isClient=${isClient}`,
      values
    );
    return Promise.resolve('Calcaulator step has been updated');
  } catch (err) {
    const reason = 'Failed to update calculator step';
    message.error(reason);
    throw new Error(reason);
  }
};

export const calculateCalculatorResults = async ({ refId }) => {
  try {
    const timeStamp = new Date().getTime();
    await axios.post(
      `${BASE_URL}/references/${refId}/results?timer=${timeStamp}`,
      { referenceId: refId }
    );
    return Promise.resolve('Calcaulator results have been calculated');
  } catch (err) {
    const reason = 'Failed to calculate calculator results';
    message.error(reason);
    throw new Error(reason);
  }
};

export const fetchCalculatorResults = async ({ refId, insuredName }) => {
  try {
    const timeStamp = new Date().getTime();
    const url = theme.isUsTheme
      ? `${BASE_URL}/references/${refId}/resulttemplateandvalues?ins=${insuredName}&timer=${timeStamp}`
      : `${BASE_URL}/references/${refId}/results?ins=${insuredName}&timer=${timeStamp}`;
    const resCalculatorResults = await axios.get(url);
    return resCalculatorResults.data;
  } catch (err) {
    const reason = 'Failed to fetch calculator results';
    message.error(reason);
    throw new Error(reason);
  }
};

export const fetchLastStepId = async (calculatorId) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/calculators/${calculatorId}/steps/last`
    );
    return res.data;
  } catch (err) {
    const reason = 'Failed to fetch last step id';
    throw new Error(reason);
  }
};

export const fetchIndustries = async (keyword) => {
  try {
    const timeStamp = new Date().getTime();
    const resIndustryResults = await axios.get(
      `${BASE_URL}/industries?timer=${timeStamp}&keyword=${keyword}&page=1&codeTypeId=12`
    );
    return resIndustryResults.data;
  } catch (err) {
    const reason = 'Failed to fetch industries results';
    message.error(reason);
    throw new Error(reason);
  }
};

export const fetchClientEmail = async ({
  calculatorId,
  oldCalculatorId,
  userCountryId,
  buyGroupId,
}) => {
  try {
    const timeStamp = new Date().getTime();
    const reqUrl = `${BASE_URL}/emails/clientEmail?timer=${timeStamp}&countryId=${userCountryId}&buygroup=${buyGroupId}&domainName=${window.location.host}&isHtml=true&newCalculatorId=${calculatorId}&oldCalculatorId=${oldCalculatorId}`;
    const resClientEmail = await axios.post(reqUrl, {});
    return resClientEmail.data;
  } catch (err) {
    const reason = 'Failed to fetch client email template';
    message.error(reason);
    throw new Error(reason);
  }
};

export const fetchResultEmail = async ({ refId, insuredName }) => {
  try {
    const timeStamp = new Date().getTime();
    const resResultEmail = await axios.get(
      `${BASE_URL}/emails/resultEmail?timer=${timeStamp}&domainName=${window.location.host}&isHtml=true&referenceId=${refId}&ins=${insuredName}`
    );
    return resResultEmail.data;
  } catch (err) {
    const reason = 'Failed to fetch result email template';
    message.error(reason);
    throw new Error(reason);
  }
};

export const fetchCalculationEmail = async ({ refId, insuredName, stepId }) => {
  try {
    const timeStamp = new Date().getTime();
    const reqUrl = `${BASE_URL}/emails/calculationEmail?timer=${timeStamp}&domainName=${window.location.host}&isHtml=true&referenceId=${refId}&stepId=${stepId}&ins=${insuredName}`;
    const resCalculationEmail = await axios.post(reqUrl);
    return resCalculationEmail.data;
  } catch (err) {
    const reason = 'Failed to fetch calculation email template';
    message.error(reason);
    throw new Error(reason);
  }
};

export const fetchResultPdf = async ({ refId }) => {
  const timeStamp = new Date().getTime();
  const resResultPdf = await axios.get(
    `${BASE_URL}/references/${refId}/results/${window.location.hostname}/Pdf?timer=${timeStamp}`,
    {
      responseType: 'arraybuffer',
    }
  );
  return resResultPdf;
};

export const sendClientEmail = async (values) => {
  try {
    const timeStamp = new Date().getTime();
    await axios.post(`${BASE_URL}/emails?timer=${timeStamp}`, values);
    return Promise.resolve('Email has been sent successfully');
  } catch (err) {
    const reason = 'Failed to send email';
    message.error(reason);
    throw new Error(reason);
  }
};

export const sendCalculatoionEmail = async ({ values, referenceId }) => {
  // values.EmailType
  // Default=0,
  // Calculation=1,
  // FinalResult=2
  try {
    const timeStamp = new Date().getTime();
    await axios.post(
      `${BASE_URL}/emails/result/final/${referenceId}?timer=${timeStamp}`,
      values
    );
    return Promise.resolve('Email has been sent successfully');
  } catch (err) {
    const reason = 'Failed to send email';
    message.error(reason);
    throw new Error(reason);
  }
};

export const sendCalculatorResults = async (values) => {
  try {
    const timeStamp = new Date().getTime();
    await axios.post(`${BASE_URL}/emails?timer=${timeStamp}`, values);
    return Promise.resolve('Email has been sent successfully');
  } catch (err) {
    const reason = 'Failed to send email';
    message.error(reason);
    throw new Error(reason);
  }
};

export const fetchGlossary = async (keyword) => {
  try {
    const definition = await axios.get(
      `${BASE_URL}/glossary?scope=bic&keyword=${keyword}`
    );
    return definition.data;
  } catch (err) {
    const reason = 'Failed to fetch glossary term';
    message.error(reason);
    throw new Error(reason);
  }
};

export const addStats = async (values) => {
  try {
    await axios.post(API_LMI_STAT, values);
    return Promise.resolve('Stat has been added');
  } catch (err) {
    const reason = 'Failed to add stats';
    return Promise.resolve(reason);
  }
};

export const fetchCountries = async () => {
  try {
    const countries = await axios.get(`${BASE_URL}/country`);
    return countries.data;
  } catch (err) {
    const reason = 'Failed to fetch countries';
    message.error(reason);
    return Promise.resolve(reason);
  }
};

export const fetchBuyerGroups = async (countryId) => {
  try {
    const buyerGroup = await axios.get(
      `${BASE_URL}/buyergroup?country=${countryId}`
    );
    return buyerGroup.data;
  } catch (err) {
    const reason = 'Failed to fetch buyer groups';
    message.error(reason);
    return Promise.resolve(err);
  }
};

export const fetchCalculationResults = async (refId) => {
  const timeStamp = new Date().getTime();
  try {
    const countries = await axios.get(
      `${BASE_URL}/results/${refId}?timer=${timeStamp}`
    );
    return countries.data;
  } catch (err) {
    const reason = 'Failed to calculation results';
    message.error(reason);
    return Promise.resolve(reason);
  }
};

export const fetchDataInputPdf = async (calculatorId) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/calculators/download/${calculatorId}/questions/pdf`,
      {
        responseType: 'arraybuffer',
      }
    );
    return res;
  } catch (err) {
    const reason = 'Failed to fetch data input pdf';
    throw new Error(reason);
  }
};

export const fetchInsuredNameList = async (userId) => {
  try {
    const res = await axios.get(`${BASE_URL}/insurednames?userId=${userId}`);
    return res.data;
  } catch (err) {
    const reason = 'Failed to fetch insured names';
    message.error(reason);
    return Promise.resolve(reason);
  }
};

export const fetchResultsSummary = async (countryId) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/resultssummary?countryId=${countryId}`
    );
    return res.data;
  } catch (err) {
    const reason = 'Failed to fetch results summary';
    message.error(reason);
    throw new Error(reason);
  }
};

export const duplicateCalculation = async (refId) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/duplicatecalculationresult/${refId}`
    );
    return res.data;
  } catch (err) {
    const reason = 'Failed to duplicate calculation results';
    message.error(reason);
    throw new Error(reason);
  }
};

export const disableCalculationResult = async (refId) => {
  try {
    const res = await axios.put(
      `${BASE_URL}/disablecalculationResult/${refId}/true`
    );
    return res.data;
  } catch (err) {
    const reason = 'Failed to delete calculation result';
    message.error(reason);
    throw new Error(reason);
  }
};

export const updateCalculationDescription = async ({ refId, description }) => {
  try {
    const res = await axios.put(`${BASE_URL}/updateresultdescription`, {
      ReferenceNumber: refId,
      description,
    });
    return res.data;
  } catch (err) {
    const reason = 'Failed to update calculation description';
    message.error(reason);
    throw new Error(reason);
  }
};

export const updateViewedReferences = async (refIds) => {
  try {
    await axios.put(`${BASE_URL}/reviewresultsbybrokerasync`, refIds);
    return Promise.resolve('references have been updated');
  } catch (err) {
    const reason = 'Failed to update viewed references';
    throw new Error(reason);
  }
};
