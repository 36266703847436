/* eslint-disable max-len */

export default {
  get baseUrl() {
    if (process.env.NODE_ENV === 'development') {
      return process.env.REACT_APP_BASE_URL;
    }
    return window.location.origin;
  },
  get usaBaseUrl() {
    return process.env.REACT_APP_BASE_USA_URL;
  },
  get urls() {
    return {
      auth: `${this.baseUrl}/lmiauth/token`,
      user: `${this.baseUrl}/lmiauth/api/user`,
      stat: `${this.baseUrl}/lmiauth/api/stats/addstats`,
      reset: `${this.baseUrl}/lmiauth/api/user/resetpassword?passwordResetTemplateUrl=www.lmigroup.com%2FLmiAuthWeb%2FResetPassword`,
      usaBase: `${this.usaBaseUrl}/api`,
      base: `${this.baseUrl}/biapi/api`,
    };
  },
};
