/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { message } from 'antd';
import { SESSION_KEY, checkUrlAccess } from '../../services/auth';
import {
  loginWithEncryptedDataAsync,
  fetchUserAsync,
  logout,
} from '../../features/auth';
import theme from '../../services/theme';
import stats from '../../services/stats';
import { addStats } from '../../features/calculators';
import { useAnalyticsEventTracker } from '../tracker/Tracker';

const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

class AccessRequestUrl {
  constructor() {
    this.url = new URL(window.location);
  }

  get linkgenToken() {
    const encryptedData = this.url.searchParams.get('EncryptedData');
    const key = this.url.searchParams.get('key');

    if (encryptedData && key) {
      return encryptedData;
    }
    return null;
  }

  get token() {
    return (
      this.url.searchParams.get('token') || localStorage.getItem(SESSION_KEY)
    );
  }
}

export const initialize = () => async (dispatch) => {
  const eventTracker = useAnalyticsEventTracker();
  const { isClientAccess } = checkUrlAccess();
  if (window.location.pathname.includes('//')) {
    const replacedPath =
      window.location.pathname.replace('//', '/') + window.location.search;
    window.location.replace(replacedPath);
  }

  // replace linkgen url for Aviva users
  if (
    window.location.search.includes('key=wbxPRzmK') &&
    window.location.pathname.includes('/Redir.aspx')
  ) {
    const replacedPath = `https://aviva.bicalculator.com/calculators${window.location.search}`;
    window.location.replace(replacedPath);
    return;
  }

  if (window.location.pathname.includes('/Redir.aspx')) {
    const replacedPath =
      window.location.pathname.replace('/Redir.aspx', '/calculators') +
      window.location.search;
    window.location.replace(replacedPath);
  }

  if (!isClientAccess) {
    const url = new AccessRequestUrl();

    axios.interceptors.response.use(
      (res) => res,
      async ({ response }) => {
        const status = response ? response.status : null;
        switch (status) {
          case StatusCodes.UNAUTHORIZED:
            message.destroy();
            message.error('Session expired. Please login again');
            dispatch(logout());
            return Promise.reject(response);
          default:
            return Promise.reject(response);
        }
      }
    );

    let token = url.linkgenToken;
    if (token) {
      await dispatch(
        loginWithEncryptedDataAsync({ token, grantType: 'LinkgenUrl' })
      );

      // login route is used for SSO login, if linkgen url is detected then trigger Linkgen stat
      if (!window.location.pathname?.includes('/login')) {
        addStats({
          projectId: PROJECT_ID,
          typeName: stats.statLabels.linkgen,
        });
        eventTracker('Login', stats.gaLabels.linkgen);
      }
    } else {
      token = url.token;
      if (token) {
        localStorage.setItem(SESSION_KEY, token);
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        await dispatch(fetchUserAsync(token));
      }
    }
  }

  theme.setSiteTheme();

  return null;
};

const initialState = { initialized: false, theme: theme.siteTheme };

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initializeApp: (state) => {
      state.initialized = true;
    },
    setTheme: (state, { payload }) => {
      state.theme = payload;
    },
  },
});

export const { initializeApp, setTheme } = appSlice.actions;

export default appSlice.reducer;
