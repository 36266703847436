/* eslint-disable react/destructuring-assignment */
import { useSelector } from 'react-redux';
import { Carousel, Row, Col, Affix } from 'antd';

import banners, {
  partnersandAds,
  santamAds,
  avivaAds,
  axaAds,
  usAds,
} from './assets';
import theme, { THEMES } from '../../services/theme';
import useWindowResize from '../hooks/useWindowResize';
import useWindowScroll from '../hooks/useWindowScroll';
import './ads.less';

const themedAds = {
  [THEMES.partnersand]: partnersandAds,
  [THEMES.santam]: santamAds,
  [THEMES.aviva]: avivaAds,
  [THEMES.axa]: axaAds,
  [THEMES.us]: usAds,
};

const BannerLink = ({ side, banner }) => (
  <a
    key={banner.key}
    href={
      side === 'rightImage' && banner?.urlRight ? banner.urlRight : banner.url
    }
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={banner[side]} alt={banner.key} />
  </a>
);

const SideAds = ({ banners, side = 'leftImage' }) => {
  const countryId = useSelector((state) => state.auth?.user?.user?.countryId);

  const selectSideAds = () => {
    if (themedAds[theme.siteTheme] && theme.siteTheme !== THEMES.default) {
      return themedAds[theme.siteTheme].map((banner) => (
        <BannerLink key={banner.key} banner={banner} side={side} />
      ));
    }

    return banners
      .filter((banner) => banner.country === countryId)
      .map((banner) => (
        <BannerLink key={banner.key} banner={banner} side={side} />
      ));
  };

  return (
    <Carousel
      className="side-banner-container"
      effect="fade"
      autoplay
      pauseOnHover={false}
      autoplaySpeed={4500}
      dots={false}
    >
      {selectSideAds()}
    </Carousel>
  );
};

const LayoutWithSideAds = ({ children }) => {
  const { height } = useWindowResize();
  const { scrollPosition } = useWindowScroll();

  const BANNER_IMAGE_HEIGHT = 600 + 20;
  const FOOTER_HEIGHT = 102;

  const getBannerPosition = () => {
    if (height && scrollPosition) {
      if (height >= BANNER_IMAGE_HEIGHT + FOOTER_HEIGHT) return 10;
      if (
        height < BANNER_IMAGE_HEIGHT + FOOTER_HEIGHT &&
        scrollPosition > 360
      ) {
        return height - (BANNER_IMAGE_HEIGHT + FOOTER_HEIGHT);
      }
    }
    return 10;
  };

  return (
    <Row className="with-side-ads">
      <Col span={0} lg={2}>
        <Affix offsetTop={getBannerPosition()}>
          <SideAds banners={banners} side="leftImage" />
        </Affix>
      </Col>
      <Col span={24} lg={20}>
        {children}
      </Col>
      <Col span={0} lg={2}>
        <Affix offsetTop={getBannerPosition()}>
          <SideAds banners={banners} side="rightImage" />
        </Affix>
      </Col>
    </Row>
  );
};

export default LayoutWithSideAds;
