/* eslint-disable react/no-unstable-nested-components */
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import SavedCalculationsActions from './SavedCalculationsActions';
import { stringSorter } from '../calculator-list/CalculatorList';
import CalculationLabel from './CalculationLabel';
import CalculationStatus from './CalculationStatus';
import ClientName from './ClientName';
import { decrypt } from '../../../../services/crypto';

const dateSorter = (a, b) => moment(b) - moment(a);

const columns = [
  {
    title: 'Client Name',
    dataIndex: 'clientName',
    key: 'clientName',
    sorter: (a, b) => stringSorter(a.clientName, b.clientName),
    render: (value, record) => <ClientName value={value} record={record} />,
  },
  {
    title: 'Insurer',
    dataIndex: 'insurer',
    key: 'insurer',
    sorter: (a, b) => stringSorter(a.insurer, b.insurer),
    responsive: ['sm'],
  },
  {
    title: 'Calculator',
    dataIndex: 'calculatorName',
    key: 'calculatorName',
    sorter: (a, b) => stringSorter(a.calculatorName, b.calculatorName),
  },
];

const calculationColumn = [
  {
    title: 'Version',
    dataIndex: 'name',
    key: 'name',
    width: '6%',
    render: (value, record) => (
      <Tooltip title={`ReferenceId : ${record.referenceId}`}>{value}</Tooltip>
    ),
  },
  {
    title: 'Modified',
    dataIndex: 'lastUpdate',
    key: 'lastUpdate',
    width: '6%',
    sorter: (a, b) => dateSorter(a.lastUpdate, b.lastUpdate),
    render: (value, record) => (
      <Tooltip
        title={`Created: ${moment
          .utc(record.created)
          .local()
          .format('DD/MM/YYYY')}`}
      >
        {moment.utc(value).local().format('DD/MM/YYYY')}
      </Tooltip>
    ),
    responsive: ['sm'],
  },
  {
    title: 'Description',
    dataIndex: 'label',
    key: 'label',
    width: '35%',
    render: (value, record) => (
      <CalculationLabel value={value} record={record} />
    ),
    sorter: (a, b) => stringSorter(a.label, b.label),
  },
  {
    title: 'Emailed to',
    dataIndex: 'emailedTo',
    key: 'emailedTo',
    width: '15%',
    responsive: ['sm'],
    sorter: (a, b) => stringSorter(a.emailedTo, b.emailedTo),
    render: (value) => decrypt(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '8%',
    render: (value, record) => (
      <CalculationStatus value={value} record={record} />
    ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (_, record) => <SavedCalculationsActions record={record} />,
  },
];

const versionData = (
  results,
  { setEmailModalState, updatedReferencesByClient, fetchResultsSummary }
) =>
  results
    .map(
      ({
        ReferenceId,
        CalculatorName,
        ResultId,
        Version,
        EmailAddress,
        Status,
        CreatedDate,
        InsuredName,
        LastUpdatedStepId,
        FistStepId,
        UpdatedDate,
        Description,
        Values,
        IsResultUpdatedByClient,
      }) => ({
        key: ReferenceId,
        name: Version,
        created: CreatedDate,
        label: Description,
        emailedTo: EmailAddress,
        status: Status,
        insuredName: InsuredName,
        referenceId: ReferenceId,
        resultId: ResultId,
        firstStepId: FistStepId,
        lastUpdatedStepId: LastUpdatedStepId,
        lastUpdate: UpdatedDate,
        calculatorName: CalculatorName,
        values: Values,
        isResultUpdatedByClient: IsResultUpdatedByClient,
        onEmailClick: (state) => setEmailModalState(state),
        newUpdate: updatedReferencesByClient.includes(ReferenceId),
        fetchResultsSummary,
      })
    )
    .sort((a, b) => dateSorter(a.created, b.created));

const VersionTable = ({ dataSource }) => (
  <Table
    className="version-table"
    size="small"
    showSorterTooltip={false}
    pagination={false}
    columns={calculationColumn}
    dataSource={dataSource}
  />
);

const SavedCalculationTable = ({
  dataSource,
  loading,
  updateViewedReferences,
  updatedReferencesByClient,
  setEmailModalState,
  fetchResultsSummary,
}) => (
  <Table
    className="saved-calculations-table"
    dataSource={dataSource}
    pagination={false}
    columns={columns}
    loading={loading}
    size="small"
    showSorterTooltip={false}
    expandable={{
      onExpand: (expanded, record) =>
        !!record.updatedByClient.length &&
        expanded &&
        updateViewedReferences(record.updatedByClient),
      expandedRowRender: ({ resultsSummary }) =>
        resultsSummary && (
          <VersionTable
            dataSource={versionData(resultsSummary, {
              setEmailModalState,
              updatedReferencesByClient,
              fetchResultsSummary,
            })}
          />
        ),
      rowExpandable: ({ resultsSummary }) => resultsSummary.length > 0,
    }}
  />
);

export default SavedCalculationTable;
