/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Select } from 'antd';
import { setInsuredName } from '../../../../calculatorsSlice';
import { fetchInsuredNameList } from '../../../../../../features/calculators';
import { selectIsUserClient } from '../../../../../../features/auth';
import { decrypt, encrypt } from '../../../../../../services/crypto';

const { Option } = Select;

export const InsuredNameInput = ({ onChange, defaultValue, ...restProps }) => {
  const isClient = useSelector((state) => selectIsUserClient(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (defaultValue) {
      dispatch(setInsuredName(defaultValue));
    }
  }, []);

  const handleChange = (e) => {
    dispatch(setInsuredName(e.target.value));
    onChange(e.target.value);
  };

  return (
    <Input
      {...restProps}
      disabled={isClient && defaultValue}
      defaultValue={defaultValue && decrypt(defaultValue)}
      onChange={handleChange}
    />
  );
};

export const SelectInsuredName = ({ onChange, defaultValue }) => {
  const [insuredNameList, setInsuredNameList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const userId = useSelector((state) => state.auth?.user?.user?.userId);
  const isClient = useSelector((state) => selectIsUserClient(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (defaultValue) {
      dispatch(setInsuredName(defaultValue));
    }
  }, []);

  const fetchInsuredList = async () => {
    if (userId) {
      setLoading(true);
      setInsuredNameList(await fetchInsuredNameList(userId));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInsuredList();
  }, []);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChange = (value) => {
    onChange?.(encrypt(value));
    dispatch(setInsuredName(encrypt(value)));
  };

  return (
    <Select
      showSearch
      defaultValue={decrypt(defaultValue)}
      onChange={handleChange}
      onSearch={handleSearch}
      disabled={isClient && defaultValue}
      notFoundContent={<div>Please enter insured name</div>}
    >
      {keyword.length > 1 && !loading && (
        <Option key="user-added-insured-name" value={keyword}>
          {keyword}
        </Option>
      )}

      {!isClient &&
        !!insuredNameList.length &&
        insuredNameList.map(
          (insuredName) =>
            !!insuredName.length && (
              <Option key={insuredName} value={decrypt(insuredName)}>
                {decrypt(insuredName) || insuredName}
              </Option>
            )
        )}
    </Select>
  );
};

export default InsuredNameInput;
