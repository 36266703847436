/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import {
  byPassMyAccountAsync,
  selectIsUserClient,
} from '../../../../features/auth';
import auth from '../../../../services/auth';
import './userActions.less';

const UserActions = ({ user, byPassUrl, isUserClient }) => {
  const dispatch = useDispatch();
  const isSharedAccount = user?.user?.isSharedAccount;

  useEffect(() => {
    // if user is client then should not request byPassUrl
    if (isSharedAccount === false && user && !byPassUrl && !isUserClient) {
      dispatch(byPassMyAccountAsync());
    }
  }, []);

  const userInfo = () => (
    <>
      {`Welcome ${auth.session.username}`}
      <FontAwesomeIcon icon={faUserAlt} className="site-header-user-icon" />
    </>
  );

  return auth.isLoggedIn ? (
    <div className="site-header-user-actions">
      <div className="site-header-user">
        {!isUserClient && (
          <span className="site-header-username">
            {isSharedAccount === false && byPassUrl ? (
              <Tooltip title="Click here to manage your account">
                <a href={byPassUrl} target="_blank" rel="noopener noreferrer">
                  {userInfo()}
                </a>
              </Tooltip>
            ) : (
              userInfo()
            )}
          </span>
        )}
      </div>
      <Button className="site-header-btn-logout" type="link">
        <a href={auth.ssoLogoutUrl}>LOG OUT</a>
      </Button>
    </div>
  ) : (
    <Link to="/login">
      <Button type="link">LOG IN</Button>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  byPassUrl: state.auth.byPassUrl,
  isUserClient: selectIsUserClient(state),
});

export default connect(mapStateToProps)(UserActions);
