/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Badge, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faBookmark } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import Layout from '../../components/layout/Layout';
import CalculatorList from './components/calculator-list/CalculatorList';
import MultiCalculatorList from './components/calculator-list/MultiCalculatorList';
import SavedCalculations from './components/saved-calculations/SavedCalculations';
import Ads from '../../components/ads/Ads';
import AdminSection from './components/admin-section/AdminSection';
import ScreenSelect from './components/screen-select/ScreenSelect';
import LayoutWithSideAds from '../../components/ads/SideAds';
import theme, { THEMES } from '../../services/theme';
import { selectIsUserAdmin } from '../../features/auth';
import {
  fetchCalculatorsListAsync,
  clearCalculatorStep,
  fetchResultsSummaryAsync,
} from './calculatorsSlice';

import './calculators.less';

export const SCREEN_LABELS = {
  Calculators: 'Calculators',
  SavedCalculations: 'Saved Calculations',
  Select: 'Select',
};

const Calculators = ({
  calculatorList,
  userCountryId,
  buyGroupId,
  isUserAdmin,
  loadingCalculatorList,
  updatedReferencesByClient,
  loadingCalculatorResultsSummary,
}) => {
  const navigate = useNavigate();
  const { calcId } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [calcListFilter, setCalcListFilter] = useState({
    showArchived: false,
    showInActive: false,
  });

  const [selectedScreen, setSelectedScreen] = useState();
  const [multiCalculators, setMultiCalculators] = useState();
  const [calculators, setCalculators] = useState();

  const fetchResultsSummary = (selectedCountry) => {
    const countryId = selectedCountry || userCountryId;
    if (isUserAdmin && theme.isUsTheme) {
      dispatch(fetchResultsSummaryAsync(9));
    } else {
      dispatch(fetchResultsSummaryAsync(countryId));
    }
  };

  const fetchCalculatorList = (selectedCountry, buyerGroupId) => {
    const countryId =
      isUserAdmin && selectedCountry ? selectedCountry : userCountryId;
    dispatch(fetchCalculatorsListAsync({ countryId, buyerGroupId }));
  };

  useEffect(() => {
    let list = [...calculatorList];
    if (!calcListFilter.showArchived) {
      list = list.filter(({ IsPolicyArchived }) => !IsPolicyArchived);
    }
    if (!calcListFilter.showInActive) {
      list = list.filter(({ IsActive }) => IsActive);
    }
    setCalculators(list);
  }, [calcListFilter, calculatorList]);

  useEffect(() => {
    dispatch(clearCalculatorStep());
    // if user is admin then fetching calculator list from admin section
    if (userCountryId && !isUserAdmin) {
      fetchCalculatorList();
    }
  }, [userCountryId]);

  useEffect(() => {
    if (theme.isUsTheme && !isUserAdmin) {
      // Set USA countryId 9 as a default
      fetchResultsSummary(9);
    }
  }, [selectedScreen]);

  useEffect(() => {
    if (state) {
      if (state.screen === SCREEN_LABELS.Calculators) {
        setSelectedScreen(SCREEN_LABELS.Calculators);
      }
      if (state.screen === SCREEN_LABELS.SavedCalculations) {
        setSelectedScreen(SCREEN_LABELS.SavedCalculations);
      }
      if (state.screen === SCREEN_LABELS.Select) {
        setSelectedScreen();
      }
    }
    if (!theme.isUsTheme) {
      setSelectedScreen(SCREEN_LABELS.Calculators);
    }
  }, [state]);

  useEffect(() => {
    if (calcId) {
      const selectedPolicyMultiCalculators = calculatorList?.find(
        (calc) => calc.CalculatorId === +calcId
      );
      setMultiCalculators(selectedPolicyMultiCalculators);
    }
  }, [calculatorList, calcId]);

  const handleMultiCalculators = (calcId) => {
    navigate(`/policy/calculators/${calcId}`);
  };

  const renderMultiCalculatorContent = () => (
    <>
      <p> Please select a calculator under the selected policy</p>
      <h3>Policy Information</h3>

      {multiCalculators?.MultiCalculatorPolicy && (
        <>
          <div>{multiCalculators.InsurerName}</div>
          <div>{multiCalculators.PolicyName}</div>
          <div>
            {`${multiCalculators.PolicyCode} (${multiCalculators.PolicyDate})`}
          </div>
        </>
      )}

      <div className="calculators-content-body">
        <MultiCalculatorList
          userCountryId={userCountryId}
          buyGroupId={buyGroupId}
          calculatorList={multiCalculators.SamePolicyCalculators}
        />
      </div>
    </>
  );

  const renderCalculatorListContent = () => (
    <>
      <div className="calculators-content-header">
        <p className="calculators-content-header-title">
          Our Business Interruption calculators are specific to individual
          policies.
        </p>

        {[THEMES.partnersand, THEMES.axa, THEMES.aviva].includes(
          theme.siteTheme
        ) ? (
          <>
            <p>
              To bring up the correct cover calculator simply click the
              appropriate &quot;Enter&quot; button. To double check that you
              have the correct policy you can click on the &quot;PDF&quot; icon
              for the wording, many of our policy documents are{' '}
              <em>&quot; Insurance Act &quot;</em> compliant.
            </p>
            <p>
              If you wish to email a link of the correct cover calculator to
              your client, accountant or the like please simply click on the
              Email icon and then email it with an explanation as to what you
              want the recipient to do with it.
            </p>
          </>
        ) : (
          <>
            <p>
              To bring up the correct cover calculator simply click the
              appropriate &quot;Enter&quot; button. To double check that you
              have the correct policy you can click on the &quot;PDF&quot; icon
              for the wording.
            </p>
            <p>
              If you wish to email a link of the correct cover calculator to
              your client, accountant or the like please simply click on the
              Email icon and then email it with an explanation as to what you
              want the recipient to do with it.
            </p>
          </>
        )}
      </div>

      {isUserAdmin && (
        <div className="calculator-status-tag">
          <Tag color="#f2dede">Inactive Calculator</Tag>
        </div>
      )}

      <div className="calculators-content-body">
        <CalculatorList
          calculatorList={calculators}
          loading={loadingCalculatorList}
          handleMultiCalculators={handleMultiCalculators}
          userCountryId={userCountryId}
          buyGroupId={buyGroupId}
        />
      </div>
    </>
  );

  const renderSavedCalculations = () => (
    <>
      <div className="calculators-content-header">
        <p className="calculators-content-header-title">
          View, edit or send completed or partially completed calculations!
        </p>
        <p>
          Calculators that have more than one version of the calculation are
          marked with the right arrow symbol. Click the arrow to view the
          version history
        </p>
      </div>
      <SavedCalculations
        loading={loadingCalculatorResultsSummary}
        userCountryId={userCountryId}
        fetchResultsSummary={fetchResultsSummary}
      />
    </>
  );

  const renderTabs = () => (
    <div className="screen-tabs">
      <div
        className={cx('tab', {
          selected: selectedScreen === SCREEN_LABELS.Calculators,
        })}
      >
        <Button
          onClick={() => setSelectedScreen(SCREEN_LABELS.Calculators)}
          type="text"
          icon={
            <FontAwesomeIcon icon={faCalculator} style={{ marginRight: 10 }} />
          }
        >
          Select Your Calculator
        </Button>
      </div>
      {theme.isUsTheme && (
        <div
          className={cx('tab', {
            selected: selectedScreen === SCREEN_LABELS.SavedCalculations,
          })}
        >
          <Badge count={updatedReferencesByClient?.length} overflowCount={99}>
            <Button
              onClick={() => setSelectedScreen(SCREEN_LABELS.SavedCalculations)}
              type="text"
              icon={
                <FontAwesomeIcon
                  icon={faBookmark}
                  style={{ marginRight: 10 }}
                />
              }
            >
              Your Saved Calculations
            </Button>
          </Badge>
        </div>
      )}
    </div>
  );

  return (
    <Layout>
      <LayoutWithSideAds>
        <div className="calculators-content">
          <Ads />
          {!selectedScreen && (
            <ScreenSelect
              selectScreen={(screen) => setSelectedScreen(screen)}
              screenLabels={SCREEN_LABELS}
            />
          )}
          {selectedScreen && (
            <>
              {isUserAdmin && (
                <AdminSection
                  calcListFilter={calcListFilter}
                  setCalcListFilter={setCalcListFilter}
                  fetchCalculatorList={fetchCalculatorList}
                  fetchResultsSummary={fetchResultsSummary}
                  loading={loadingCalculatorList}
                />
              )}
              {renderTabs()}
              {selectedScreen === SCREEN_LABELS.Calculators
                ? calcId && multiCalculators
                  ? renderMultiCalculatorContent()
                  : renderCalculatorListContent()
                : renderSavedCalculations()}
            </>
          )}
        </div>
      </LayoutWithSideAds>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  calculatorList: state.calculators.calculatorList,
  userCountryId: state.auth?.user?.user?.countryId,
  buyGroupId: state.auth?.user?.user?.buygroupId,
  isUserAdmin: selectIsUserAdmin(state),
  updatedReferencesByClient:
    state.calculators?.resultsSummary?.ClientUpdatedResults,
  loadingCalculatorList: state.calculators.calculatorListStatus === 'loading',
  loadingCalculatorResultsSummary:
    state.calculators.calculatorResultsStatus === 'loading',
});

export default connect(mapStateToProps)(Calculators);
