import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

const Default = () => (
  <Row>
    <Col className="site-footer-nav-container" xs={14} md={18}>
      <ul className="site-footer-nav">
        <li className="site-footer-nav-item">
          <a
            href="https://www.lmigroup.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            OUR SERVICES
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://www.lmigroup.io/our-team/"
            target="_blank"
            rel="noopener noreferrer"
          >
            OUR EXPERTS
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/terms-conditions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            TERMS &amp; CONDITIONS
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/financial-services-guide/"
            target="_blank"
            rel="noopener noreferrer"
          >
            FINANCIAL SERVICES GUIDE
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRIVACY &amp; SECURITY
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/complaints/"
            target="_blank"
            rel="noopener noreferrer"
          >
            COMPLAINTS
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CONTACT US
          </a>
        </li>
      </ul>
    </Col>
    <Col xs={10} md={6}>
      <ul className="site-footer-social-links">
        <li className="site-footer-social-link-item">
          <a
            href="https://www.facebook.com/LMIGroupPtyLtd/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </li>
        <li className="site-footer-social-link-item">
          <a
            href="https://www.youtube.com/user/LMIGROUP"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </li>
        <li className="site-footer-social-link-item">
          <a
            href="https://www.linkedin.com/company/lmi-group/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </li>
      </ul>
    </Col>
  </Row>
);

export default Default;
