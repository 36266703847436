import { Row, Col } from 'antd';
import cx from 'classnames';
import { Default, Aviva, Axa, Partnersand, Santam } from './contents';
import theme, { THEMES } from '../../services/theme';
import './footer.less';

const footerContents = {
  [THEMES.default]: <Default />,
  [THEMES.aviva]: <Aviva />,
  [THEMES.axa]: <Axa />,
  [THEMES.partnersand]: <Partnersand />,
  [THEMES.santam]: <Santam />,
};

const copyRightExcludedThemes = [THEMES.aviva];

const DefaultCopyRight = () => (
  <p className="site-footer-copyright">
    &copy; 1999-{new Date().getFullYear()} LMI Group Pty Ltd | ABN 69 086 256
    171 | AFS Licence No. 283777
  </p>
);

const Footer = () => (
  <footer
    className={cx('site-footer', {
      aviva: theme.siteTheme === THEMES.aviva,
    })}
  >
    <div className="site-footer-container">
      {footerContents[theme.siteTheme] || <Default />}
      {!copyRightExcludedThemes.includes(theme.siteTheme) && (
        <Row>
          <Col span={24}>
            <DefaultCopyRight />
          </Col>
        </Row>
      )}
    </div>
  </footer>
);

export default Footer;
