import { Layout } from 'antd';
import cx from 'classnames';
import { Header } from '../header';
import Footer from '../footer/Footer';
import './layout.less';

const { Content } = Layout;

export default ({ children, className }) => (
  <Layout className="layout-container">
    <Header />
    <Content>
      <div className={cx('site-container', className)}>{children}</div>
    </Content>
    <Footer />
  </Layout>
);
