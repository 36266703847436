const THEME_KEY = 'bic_theme';

export const THEMES = {
  axa: 'axa',
  aviva: 'aviva',
  santam: 'santam',
  partnersand: 'partnersand',
  us: 'us',
  ca: 'ca',
  default: 'default',
};

export const LOGOS = {
  [THEMES.default]: { image: '/assets/logo.svg', linkType: 'local', url: '/' },
  [THEMES.us]: { image: '/assets/logo.svg', linkType: 'local', url: '/' },
  [THEMES.aviva]: {
    image: '/assets/aviva-logo.png',
    url: 'https://connect.avivab2b.co.uk/broker/',
    linkType: 'external',
  },
  [THEMES.axa]: {
    image: '/assets/axa-logo.png',
    url: '/calculators',
    linkType: 'local',
  },
  [THEMES.santam]: {
    image: '/assets/santam-logo.png',
    url: 'https://www.santam.co.za/',
    linkType: 'external',
  },
  [THEMES.partnersand]: {
    image: '/assets/partnersand-logo.png',
    url: '/calculators',
    linkType: 'local',
  },
};

const themeSelector = (subDomain) => {
  if (subDomain.includes(THEMES.axa)) {
    return THEMES.axa;
  }
  if (subDomain.includes(THEMES.aviva)) {
    return THEMES.aviva;
  }
  if (subDomain.includes(THEMES.santam)) {
    return THEMES.santam;
  }
  if (subDomain.includes(THEMES.partnersand)) {
    return THEMES.partnersand;
  }
  if (subDomain.includes(THEMES.us) || subDomain.slice(0, 2) === THEMES.ca) {
    return THEMES.us;
  }
  return THEMES.default;
};

export default {
  get subDomain() {
    const subDomainUrl = window.location.hostname.split('.')[0];
    const domainUrl = window.location.hostname.split('.')[1];
    return `${subDomainUrl}${domainUrl}`;
  },
  get siteTheme() {
    return localStorage.getItem(THEME_KEY);
  },
  get logo() {
    return LOGOS[this.siteTheme];
  },
  get isUsTheme() {
    return this.siteTheme === THEMES.us;
  },
  setSiteTheme() {
    localStorage.setItem(THEME_KEY, themeSelector(this.subDomain));
  },
};
