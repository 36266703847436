/* eslint-disable no-unused-vars */
import cx from 'classnames';
import { Tooltip, Badge } from 'antd';
import { decrypt } from '../../../../services/crypto';

const ClientName = ({ value, record }) => (
  <Tooltip
    title={
      record.updatedByClient.length
        ? `${record.updatedByClient.length} update by client`
        : ''
    }
  >
    <Badge count={record.updatedByClient.length} offset={[15, 2]}>
      <div
        className={cx('client-name', {
          new: record.updatedByClient.length > 0,
        })}
      >
        {decrypt(value)}
      </div>
    </Badge>
  </Tooltip>
);

export default ClientName;
