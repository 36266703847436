import { Col, Row } from 'antd';

const Aviva = () => (
  <Row>
    <Col className="site-footer-nav-container" xs={14} md={18}>
      <ul className="site-footer-nav">
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/terms-conditions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms &amp; Conditions
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://lmigroup.io/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy &amp; Security
          </a>
        </li>
        <li className="site-footer-nav-item">
          <a
            href="https://connect.avivab2b.co.uk/public/broker/contact-us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us
          </a>
        </li>
      </ul>
    </Col>
  </Row>
);

export default Aviva;
