import { useState, useEffect } from 'react';

const useWindowScroll = () => {
  const [scrollPosition, setScrollPosition] = useState();

  useEffect(() => {
    const onScroll = () => {
      const scrollTop =
        window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop;
      setScrollPosition(scrollTop);
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return { scrollPosition };
};

export default useWindowScroll;
