export default {
  get statLabels() {
    return {
      linkgen: 'NewLinkgen',
      sso: 'SSO',
      login: 'Login',
      resultDownload: 'ResultDownload',
      resultEmail: 'ResultEmail',
      clientLink: 'ClientLink',
      clientLinkClicked: 'ClientLinkClicked',
      calculatorCompleted: 'CalculatorCompleted',
      calculatorStarted: 'CalculatorStarted',
    };
  },
  get gaLabels() {
    return {
      linkgen: 'NewLinkgen',
      login: 'Login',
      sso: 'SSO',
      resultDownload: 'ResultDownload',
      resultEmail: 'ResultEmail',
      clientLink: 'ClientLink',
      clientLinkClicked: 'ClientLinkClicked',
    };
  },
};
