import { message } from 'antd';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';

export const SESSION_KEY = 'bic_token';
export const SESSION_USERNAME = 'bic_username';
export const SESSION_EXPIRY = 'bic_expiry';
export const SSO_BASE_URL = process.env.REACT_APP_API_SSO_BASE_URL;
const LMI_PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const setAuthorizationHeader = () => {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    SESSION_KEY
  )}`;
  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      const status = err && err.response ? err.response.status : null;
      switch (status) {
        case StatusCodes.UNAUTHORIZED:
          message.destroy();
          message.error('Session expired. Please login again');
          window.location.replace('/');
          return Promise.reject(err);
        default:
          return Promise.reject(err);
      }
    }
  );
};

export default {
  get session() {
    return {
      token: localStorage.getItem(SESSION_KEY),
      username: localStorage.getItem(SESSION_USERNAME),
      expiry: localStorage.getItem(SESSION_EXPIRY),
    };
  },
  get isLoggedIn() {
    const expiry = localStorage.getItem(SESSION_EXPIRY);
    if (!expiry || expiry <= Date.now()) return false;
    return !!localStorage.getItem(SESSION_KEY);
  },
  get callbackLoginUrl() {
    return `${window.location.origin}/login`;
  },
  get callbackLogoutUrl() {
    return `${window.location.origin}/logout`;
  },
  get ssoLoginUrl() {
    return `${SSO_BASE_URL}/?projectId=${LMI_PROJECT_ID}&callbackUrl=${this.callbackLoginUrl}`;
  },
  get ssoLogoutUrl() {
    return `${SSO_BASE_URL}/Signout?callbackUrl=${this.callbackLogoutUrl}`;
  },
  login(token, username, expiry) {
    if (!token) throw new Error('Invalid token');
    localStorage.setItem(SESSION_KEY, token);
    localStorage.setItem(SESSION_USERNAME, username);
    localStorage.setItem(SESSION_EXPIRY, Date.now() + expiry * 1000);
    setAuthorizationHeader();
  },
  logout() {
    localStorage.removeItem(SESSION_KEY);
    localStorage.removeItem(SESSION_USERNAME);
    localStorage.removeItem(SESSION_EXPIRY);
  },
  setAuthorizationHeader,
};

export const checkUrlAccess = () => {
  const url = new URL(window.location);
  const encryptedData = url.searchParams.get('EncryptedData');
  const calcId = url.searchParams.get('calcId');
  const policyId = url.searchParams.get('policyId');
  const referenceId =
    url.searchParams.get('ReferenceNumber') ||
    url.searchParams.get('ReferenceId');
  const stepId =
    url.searchParams.get('steps') || url.searchParams.get('stepId');
  const isClientAccess =
    (encryptedData && calcId) || (encryptedData && referenceId && stepId);
  const isManualLogin =
    process.env.NODE_ENV === 'development' &&
    url.searchParams.get('manualLogin');

  return {
    url,
    encryptedData,
    policyId,
    referenceId,
    stepId,
    isClientAccess,
    isManualLogin,
  };
};
