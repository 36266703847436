/* eslint-disable consistent-return */
import { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Spinner from './components/spinner/Spinner';
import Tracker from './components/tracker/Tracker';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import auth from './services/auth';
import { selectIsUserClient } from './features/auth';
import CalcalatorReferences from './scenes/calculators/scenes/calculator-references';
import LogoutPage from './scenes/login/components/logout-page/LogoutPage';
import theme, { THEMES } from './services/theme';

const Home = lazy(() => import('./scenes/home/Home'));
const LoginScene = lazy(() => import('./scenes/login'));
const Calculators = lazy(() => import('./scenes/calculators'));
const Calculator = lazy(() => import('./scenes/calculators/scenes/calculator'));
const NotFound = lazy(() => import('./scenes/not-found'));

const PrivateRoute = ({ clientAccess, component: RouteComponent }) => {
  const isUserClient = useSelector(selectIsUserClient);

  if (!auth.isLoggedIn) {
    window.location.replace('/login');
    return null;
  }

  if (!clientAccess && isUserClient) {
    return <Navigate to="/" replace />;
  }

  return <RouteComponent />;
};

const HomepageRoute = ({
  redirectPath = '/calculators',
  component: RouteComponent,
}) => {
  // Brand site should not have access to homepage
  if (theme.siteTheme !== THEMES.default) {
    return <Navigate to={redirectPath} />;
  }

  return <RouteComponent />;
};

export default () => (
  <BrowserRouter>
    <Tracker />
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<HomepageRoute component={Home} />} />
        <Route path="/login" element={<LoginScene />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route
          path="/calculators"
          element={
            <ErrorBoundary>
              <PrivateRoute component={Calculators} />
            </ErrorBoundary>
          }
        />
        <Route
          path="/policy/calculators/:calcId"
          element={
            <ErrorBoundary>
              <PrivateRoute component={Calculators} />
            </ErrorBoundary>
          }
        />
        <Route
          path="/calculators/:calcId/referencenumber"
          element={
            <ErrorBoundary>
              <PrivateRoute clientAccess component={CalcalatorReferences} />
            </ErrorBoundary>
          }
        />
        <Route
          path="/references/:refId/steps/:stepId"
          element={
            <ErrorBoundary>
              <PrivateRoute clientAccess component={Calculator} />
            </ErrorBoundary>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);
