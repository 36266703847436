import { Tooltip, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const CalculationStatus = ({ value, record }) => {
  if (value === 1 && record.newUpdate) {
    return (
      <Tooltip title="updated by client">
        <Tag icon={<FontAwesomeIcon icon={faCheckCircle} />} color="green">
          In progress
        </Tag>
      </Tooltip>
    );
  }
  if (value === 2 && record.newUpdate) {
    return (
      <Tooltip title="updated by client">
        <Tag icon={<FontAwesomeIcon icon={faCheckCircle} />} color="blue">
          Completed
        </Tag>
      </Tooltip>
    );
  }
  if (value === 0) {
    return <Tag color="red">Completed - Old</Tag>;
  }
  if (value === 1) {
    return <Tag color="green">In progress</Tag>;
  }
  if (value === 2) {
    return <Tag color="blue">Completed</Tag>;
  }
  if (value === 3) {
    return <Tag color="magenta">Locked</Tag>;
  }
  if (value === 4) {
    return <Tag color="yellow">Duplicated</Tag>;
  }
  if (value === 5) {
    return <Tag color="orange">Email sent</Tag>;
  }
  return <Tag color="red" />;
};

export default CalculationStatus;
