/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../spinner/Spinner';
import { initialize, initializeApp } from './appSlice';
import theme, { THEMES } from '../../services/theme';

import './App.less';
import '../../styles/themes/index.less';

export default ({ children }) => {
  const dispatch = useDispatch();
  const initialized = useSelector((state) => state.app.initialized);

  useEffect(() => {
    const init = async () => {
      await dispatch(initialize());
      dispatch(initializeApp());
    };
    init();
  }, []);

  if (!initialized) {
    return <Spinner />;
  }

  return <div className={theme.siteTheme || THEMES.default}>{children}</div>;
};
