/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const BASE_URL = api.urls.base;

export const initialState = {
  media: null,
  adsStatus: 'idle',
};

export const fetchBanner = async ({ userId, bannerId }) => {
  try {
    const resBanner = await axios.get(
      `${BASE_URL}/user/${userId}/media/${bannerId}`
    );
    return resBanner.data;
  } catch (err) {
    const reason = 'Failed to fetch banner';
    throw new Error(reason);
  }
};

export const fetchBannerAsync = createAsyncThunk('ads/banner', fetchBanner);

export const adsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    clearAds: (state) => {
      state.media = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannerAsync.pending, (state) => {
        state.adsStatus = 'loading';
      })
      .addCase(fetchBannerAsync.rejected, (state) => {
        state.adsStatus = 'idle';
      })
      .addCase(fetchBannerAsync.fulfilled, (state, { payload }) => {
        state.adsStatus = 'idle';
        state.media = payload;
      });
  },
});

export const { clearAds } = adsSlice.actions;
export const selectAds = (state) => state.ads.media;

export default adsSlice.reducer;
